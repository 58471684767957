<template>
  <div class="feature times-travel-feature">
    <div class="feature-content">
      <img :src="`${useCdnUrl()}/assets-2024/events/events-logo.png`" alt="Times Travel" />
      <h2>New Year's Eve Ball Design Reveal</h2>
      <p>
        A new tradition to celebrate the past with a timeless keepsake and to preview what the
        future will hold. Limited crystals from the current New Year's Eve Ball will be given
        throughout December in Times Square.
      </p>
      <TheButton to="" class="times-travel-callout">LEARN MORE</TheButton>
    </div>

    <div class="feature-image-effect">
      <SplitImage :src="`${useCdnUrl()}/assets-2024/events/events-poster.jpg`" />
    </div>

    <div
      class="feature-image-mobile"
      :style="{ 'background-image': `url(${useCdnUrl()}/assets-2024/events/events-poster.jpg)` }"
    />
  </div>
</template>

<style lang="scss" scoped>
.times-travel-feature {
  .feature-content {
    background-color: #f5f4bc;

    h2 {
      line-height: 1;
      margin: 0;
    }

    h2,
    p {
      color: black;
    }

    .times-travel-callout {
      background-color: #9a9974;
      border-width: 0;

      &:hover {
        color: #9a9974;
      }
    }
  }
}
</style>
